import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const QueryStringBuilder = (props) => {
    const [searchParams] = useSearchParams();
    const [isInitialized, setIsInitialized] = useState(false);

    const [searchQuery, setSearchQuery] = useState({
        CountryId: null,
        ResortId: null,
        ResortIds: [],
        ChaletId: null,
        DateFrom: null,
        DateTo: null,
        Adults: null,
        Children: null,
        Infants: null,
        TransferId: null,
        FoodId: null,
        HireId: null,
        LiftPassId: null,
        AirportFromId: null,
        AirportToId: null,
        Rooms: null,
        Currency: null,
        AttributeRefinerIds: [],
        PageSize: 25,
        PageCount: 0,
        SortDirection: null,
        queryStringNull: true,
        TotalCost: null,
        CountrySearchArray: [],
        CustomSearchArray: [],
        SearchType: "SingleResort"
    });

    useEffect(() => {
        // If already initialized, send data to parent and exit
        if (isInitialized) {
            props.sendDataToParent(searchQuery);
            return;
        }

        let searchReplace = {};

        Object.keys(searchQuery).forEach(key => {
            const lowerCaseKey = key.toLowerCase();
            const searchParamValue = searchParams.get(lowerCaseKey) || searchParams.get(key);

            // Convert to number if needed and valid
            if (searchParamValue !== null && !isNaN(searchParamValue) && !searchParamValue.includes(',')) {
                searchReplace[key] = Number(searchParamValue);
            } else if (searchParamValue !== null && searchParamValue.includes(',')) {
                // Convert comma-separated values to array of numbers
                searchReplace[key] = searchParamValue.split(',').map(Number);
            } else {
                searchReplace[key] = searchParamValue;
            }

            // Default values
            if (lowerCaseKey === 'pagesize' && searchReplace[key] == null) {
                searchReplace[key] = searchQuery.PageSize;
            }
            if (lowerCaseKey === 'pagecount' && searchReplace[key] == null) {
                searchReplace[key] = searchQuery.PageCount;
            }
            if (lowerCaseKey === 'attributerefinerids' && searchReplace[key] == null) {
                searchReplace[key] = searchQuery.AttributeRefinerIds;
            }

            if (lowerCaseKey === 'searchtype' && searchReplace[key] == null) {
                searchReplace[key] = searchQuery.SearchType;
            }

            // Dates
            if ((searchParams.get('dateto') || searchParams.get('DateTo')) !== null && lowerCaseKey === 'dateto') {
                searchReplace['DateTo'] = searchParamValue;
            }
            if ((searchParams.get('datefrom') || searchParams.get('DateFrom')) !== null && lowerCaseKey === 'datefrom') {
                searchReplace['DateFrom'] = searchParamValue;
            }

            // Resort and Country Ids
            if (lowerCaseKey === 'resortids' && searchParamValue) {
                if (searchParamValue.includes(',')) {
                    searchReplace[key] = searchParamValue.split(',').map(Number);
                } else {
                    searchReplace[key] = [Number(searchParamValue)];
                }
            }
            if (lowerCaseKey === 'countrysearcharray' && searchParamValue) {
                if (searchParamValue.includes(',')) {
                    searchReplace[key] = searchParamValue.split(',').map(Number);
                } else {
                    searchReplace[key] = [Number(searchParamValue)];
                }
            }
        });

        // Ensure AttributeRefinerIds is an array if null
        if (searchReplace.AttributeRefinerIds == null) {
            searchReplace.AttributeRefinerIds = [];
        }

        // Ensure CountrySearchArray is an array if null
        if (searchReplace.CountrySearchArray == null) {
            searchReplace.CountrySearchArray = [];
        }

        // Ensure ResortIds is an array if null
        if (searchReplace.ResortIds == null) {
            searchReplace.ResortIds = [];
        }

        // Ensure CustomSearchArray is an array if null
        if (searchReplace.CustomSearchArray == null) {
            searchReplace.CustomSearchArray = [];
        }

        if (Array.from(searchParams).length > 0) {
            searchReplace.queryStringNull = false;
        } else {
            searchReplace.queryStringNull = true;
        }

        setSearchQuery(searchReplace); // Set the local state
        setIsInitialized(true); // Mark as initialized
        props.sendDataToParent(searchReplace); // Send the updated object to the parent

    }, [searchParams, props, searchQuery, isInitialized]);

    return null;
}

export default QueryStringBuilder;
