import React, { useState, useEffect } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/en-gb';

moment.locale('en-gb');

const ChaletAwayDatePicker = (props) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [datePickerVisible, setDatePickerVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1200); // Check if the screen width is less than or equal to 768px
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize); // Add resize listener

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up listener on unmount
        };
    }, []);

    useEffect(() => {
        if (!datePickerVisible) {
            if ((startDate === null || endDate === null) && props.selectedDate && props.selectedDate.length === 2) {
                const [selectedStartDate, selectedEndDate] = props.selectedDate;
                if (selectedStartDate) {
                    const parsedStartDate = moment(selectedStartDate, 'YYYY-MM-DD');
                    setStartDate(parsedStartDate);
                }
                if (selectedEndDate) {
                    const parsedEndDate = moment(selectedEndDate, 'YYYY-MM-DD');
                    setEndDate(parsedEndDate);
                }
                setDatePickerVisible(true);
            }
        }
    }, [props.selectedDate, startDate, endDate]);

    const onDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
        const formattedStartDate = startDate ? startDate.format('DD/MM/YYYY') : null;
        const formattedEndDate = endDate ? endDate.format('DD/MM/YYYY') : null;
        props.updateDate([formattedStartDate, formattedEndDate]);
    };

    const handleClick = () => {
        setDatePickerVisible(true);
    };

    return (
        <div id="container" className="date-picker-container" onClick={handleClick}>
            {datePickerVisible ? (
                <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    startDateId="startDate"
                    endDateId="endDate"
                    minimumNights={1}
                    showClearDates={true}
                    numberOfMonths={isMobile ? 1 : 2}  // Display 1 month on mobile, 2 months on larger screens
                    isOutsideRange={day => day.isBefore(moment(), 'day')}
                    displayFormat="DD/MM/YYYY"
                    noBorder={true}
                    readOnly={true}
                />
            ) : (
                <div className="date-picker-label">
                    When
                </div>
            )}
        </div>
    );
}

export default ChaletAwayDatePicker;
