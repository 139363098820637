import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga4';

const cookies = new Cookies();

const CookieConsent = () => {
    const [consentBoxVisible, setConsentBoxVisible] = useState(false);

    useEffect(() => {
        const cookieConsent = cookies.get('cookieconsent');
        if (cookieConsent === 'true') {
            initializeAnalytics();
        } else if (cookieConsent !== 'false') {
            setConsentBoxVisible(true);
        }
    }, []);

    const confirmAgreement = (option) => {
        cookies.set('cookieconsent', option.toString(), { path: '/' });
        setConsentBoxVisible(false);
        if (option) {
            initializeAnalytics();
        }
    };

    const initializeAnalytics = () => {
        ReactGA.initialize('G-DN5MBZ5W53'); // Use your actual Measurement ID
        trackPageView();
    };

    const trackPageView = () => {
        const page = window.location.pathname + window.location.search;
        ReactGA.send({ hitType: "pageview", page: page });
    };

    return (
        <Modal isOpen={consentBoxVisible} toggle={() => setConsentBoxVisible(!consentBoxVisible)}>
            <ModalHeader>Cookie Settings</ModalHeader>
            <ModalBody>
                ChaletAway values your privacy above all. We use necessary cookies for essential site functions, and others to enhance your experience, aiming for the smoothest path to perfect powder. Your consent for us to use these would be warmly welcomed. However, you're free to decline if you wish.
                Warm regards, The CA Team
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => confirmAgreement(true)}>OK</Button>{' '}
                <Button color="secondary" onClick={() => confirmAgreement(false)}>Opt Out</Button>
            </ModalFooter>
        </Modal>
    );
};

export default CookieConsent;