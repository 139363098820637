import React, { useEffect } from 'react';
import { SearchBar } from '../NavMenu/SearchBar';
import ShortTransferWebpart from '../DynamicPages/Webparts/ShortTransferWebpart';
import TopDestinationsWebpart from '../DynamicPages/Webparts/TopDestinations';
import ChristmasSkiWebpart from '../DynamicPages/Webparts/ChristmasSkiWebpart';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';

// Functional Component to handle responsive rendering
const ResponsiveAccordion = ({ children, title, defaultExpanded = false }) => {
    // Hook to detect if the screen width is mobile size (600px or less)
    const isMobile = useMediaQuery('(max-width:600px)');

    // Conditionally render Accordion for mobile and standard layout for larger screens
    if (isMobile) {
        return (
            <Accordion defaultExpanded={defaultExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${title.toLowerCase().replace(/\s+/g, '-')}-content`}
                    id={`${title.toLowerCase().replace(/\s+/g, '-')}-header`}
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        );
    }

    // For non-mobile devices, render content directly without Accordion
    return <div>{children}</div>;
};

// Functional Component: Home
const Home = (props) => {
    useEffect(() => {
        // Set window.ServerLoading to false when the component is mounted
        window.ServerLoading = false;
       
        // Optionally clean up or other side effects can go here
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    return (
        <div>
            <Helmet>
                {/* Primary SEO Tags */}
                <title>ChaletAway - Find Your Perfect Ski Chalet & Luxury Family Holidays in the Alps</title>
                <meta
                    name="description"
                    content="Explore ChaletAway for the best ski chalets in the Alps. Book luxury and family-friendly holidays with short transfers, top amenities, and scenic views."
                />
                <meta name="keywords" content="ski chalets, Alps ski holidays, family ski chalets, luxury ski holidays" />

                {/* Canonical URL */}
                <link rel="canonical" href="https://www.chaletaway.com" />

                {/* JSON-LD Structured Data for SEO */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "TravelAgency",
                        "name": "ChaletAway",
                        "url": "https://www.chaletaway.com",
                        "logo": "https://www.chaletaway.com/Chaletawaylogo.png",
                        "description": "ChaletAway is your go-to platform for finding the perfect ski chalet in the Alps. Whether you're looking for a cozy, family-friendly option or a luxury chalet near the slopes, ChaletAway helps you discover top-rated chalets for your ideal holiday getaway.",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "Customer Service",
                            "areaServed": "GB",
                            "availableLanguage": ["English"]
                        },
                        "sameAs": [
                            "https://www.facebook.com/HutteTravel",
                            "https://www.instagram.com/HutteTravel",
                            "https://twitter.com/Huttetravel"
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "86-90 Paul Street",
                            "addressLocality": "London",
                            "addressRegion": "England",
                            "postalCode": "EC2A 4NE",
                            "addressCountry": "GB"
                        },
                        "image": [
                            "https://www.chaletaway.com/static/media/hotel-1.ba89d6598c7d7257f1fd.jpg",
                        ],
                        "areaServed": {
                            "@type": "Place",
                            "name": "Europe",
                            "address": {
                                "@type": "PostalAddress",
                                "addressCountry": "GB"
                            }
                        },
                        "offers": [
                            {
                                "@type": "Offer",
                                "name": "Short Transfer Chalets",
                                "description": "Ski chalets with short airport transfers for maximizing your skiing time.",
                                "url": "https://www.chaletaway.com/ShortTransfer"
                            },
                            {
                                "@type": "Offer",
                                "name": "Christmas Ski Chalets",
                                "description": "Chalets available for skiing during the Christmas season in the Alps.",
                                "url": "https://www.chaletaway.com/ChristmasSki"
                            },
                            {
                                "@type": "Offer",
                                "name": "New Year Ski Chalets",
                                "description": "Start the year with an unforgettable ski holiday in the Alps.",
                                "url": "https://www.chaletaway.com/NewYearSki"
                            },
                            {
                                "@type": "Offer",
                                "name": "Easter Ski Chalets",
                                "description": "Enjoy spring skiing with a range of chalets available for the Easter holiday.",
                                "url": "https://www.chaletaway.com/EasterSki"
                            },
                            {
                                "@type": "Offer",
                                "name": "Half Term Ski Chalets",
                                "description": "Family-friendly chalets available during the February half-term break.",
                                "url": "https://www.chaletaway.com/HalfTermSki"
                            },
                            {
                                "@type": "Offer",
                                "name": "Late Season Ski Chalets",
                                "description": "Ideal chalets for skiing in the late season with snow-sure resorts.",
                                "url": "https://www.chaletaway.com/LateSeasonSki"
                            },
                            {
                                "@type": "Offer",
                                "name": "Close to Calais Chalets",
                                "description": "Convenient chalets close to Calais, perfect for a driving ski holiday.",
                                "url": "https://www.chaletaway.com/CloseToCalais"
                            },
                            {
                                "@type": "Offer",
                                "name": "Early Season Ski Chalets",
                                "description": "Book early season ski chalets with reliable snow cover in the Alps.",
                                "url": "https://www.chaletaway.com/EarlySeasonSki"
                            },
                            {
                                "@type": "Offer",
                                "name": "Family-Friendly Chalets",
                                "description": "Browse family-friendly chalets perfect for a winter holiday with kids.",
                                "url": "https://www.chaletaway.com/FamilyFriendly"
                            },
                            {
                                "@type": "Offer",
                                "name": "Scenic View Chalets",
                                "description": "Discover chalets with stunning mountain views for a picturesque ski holiday.",
                                "url": "https://www.chaletaway.com/ScenicViews"
                            },
                            {
                                "@type": "Offer",
                                "name": "Large Ski Area Chalets",
                                "description": "Stay in resorts with vast skiing areas, perfect for all skill levels.",
                                "url": "https://www.chaletaway.com/LargeSkiingArea"
                            },
                            {
                                "@type": "Offer",
                                "name": "Good Off-Piste Chalets",
                                "description": "Find chalets near prime off-piste areas for experienced skiers.",
                                "url": "https://www.chaletaway.com/GoodOffPiste"
                            }
                        ]
                    })}
                </script>
            </Helmet>
            <section className="home-slider owl-carousel">
                <div className="slider-item" id="bg_1">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row slider-text align-items-center">
                            <div className="col-md-7 col-sm-12 ftco-animate">
                                <h1 className="mb-3">Experience the best trip ever</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <SearchBar location={props.location} />

            <section className="ftco-section-2">
                <div className="container-fluid d-flex">
                    <div className="section-2-blocks-wrapper row no-gutters">
                        <div className="img col-sm-12 col-lg-6" id="main-image" >
                        </div>
                        <div className="text col-lg-6">
                            <div className="text-inner align-self-start">
                                <h3>Discover Your Perfect Luxury Ski Chalet with ChaletAway</h3>
                                <div className="align-self-center">
                                    <p>
                                        Launched in the crisp winter of 2024, ChaletAway quickly emerged as the ultimate ski chalet comparison platform for discerning skiers, snowboarders, and Alpine adventurers. Filling a much-needed space in the holiday market, ChaletAway serves as a dedicated guide for finding the ideal Christmas chalet, whether for a snow-sure escape, a luxurious ski getaway, or a family-friendly ski holiday in the Alps.
                                        With ChaletAway, holidaymakers can easily discover the best chalets for a Christmas ski adventure. Each option on our platform is handpicked to meet the needs of both seasoned snow enthusiasts and families eager for a memorable mountain retreat. As we grow and refine our services, we appreciate your patience and support, and we're always thrilled to connect! If you have any questions or would like to discuss a partnership, don't hesitate to reach out - we love a friendly chat and are dedicated to helping you find that perfect snowy escape.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ResponsiveAccordion title="Explore resorts for Christmas Ski" defaultExpanded={true}>
                <div className="row no-gutters">
                    <div className="col-12 text-left heading-section">
                        <div class="christmas-ski-section p-3 lh-lg">
                            <h2>Celebrate Christmas on the Slopes - Find the Perfect Ski Chalet for a Magical Holiday!</h2>
                            <p>
                                Looking to spend Christmas in a winter wonderland? Discover our handpicked selection of ski chalets that offer the perfect festive getaway in snow-sure resorts. Whether you're planning a family Christmas ski holiday or a romantic alpine escape, these chalets provide everything you need for an unforgettable holiday season. Enjoy cozy nights by the fire, stunning mountain views, and easy access to world-class skiing.
                            </p>
                            <p>
                                From luxury chalets to budget-friendly options, explore our top-rated Christmas ski accommodations and make this holiday season one to remember. Start planning your dream Christmas ski holiday now!
                            </p>
                        </div>
                    </div>
                </div>
                <ChristmasSkiWebpart />
            </ResponsiveAccordion>
            <ResponsiveAccordion title="Discover Ski Chalets with Quick Airport Transfers - Your Slope-Side Stay Starts Here" defaultExpanded={true}>
                <ShortTransferWebpart />
            </ResponsiveAccordion>
            <ResponsiveAccordion title="Explore Our Most Popular Destinations!" defaultExpanded={true}>
                <TopDestinationsWebpart />
            </ResponsiveAccordion>

          
        </div>
    );
};

export default Home;
