import React, { useEffect, useState } from 'react';

const FooterLinks = () => {
    const [FooterLinksData, setFooterLinksData] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetch(apiUrl + '/NavMenu')
            .then((res) => res.json())
            .then((data) => {
                setFooterLinksData(data);
            })
            .catch(console.log);
    }, []);

    const generateUrl = (base, path, resortId, countryId) => {
        // Define the fixed parameters
        const adults = 2;
        const children = 2;
        const rooms = 1;

        // Define dynamic date parameters
        const dateFrom = new Date();
        dateFrom.setDate(dateFrom.getDate() + 30); // Today + 30 days
        const dateTo = new Date();
        dateTo.setDate(dateTo.getDate() + 37); // Today + 37 days

        // Format dates to YYYY-MM-DD
        const formattedDateFrom = dateFrom.toISOString().split('T')[0];
        const formattedDateTo = dateTo.toISOString().split('T')[0];

        // Construct the query string
        const queryString = `?CountryId=${countryId}&ResortId=${resortId}&DateFrom=${formattedDateFrom}&DateTo=${formattedDateTo}&Adults=${adults}&Children=${children}&Rooms=${rooms}`;

        // Return the full URL
        return `${base}/${path}${queryString}`;
    };


    if (FooterLinksData.length === 0) {
        return null;
    }

    return (
        <>
            {FooterLinksData.map(({ country, resorts }, i) => (
                <div className="col-md-2" key={i}>
                    <div className="ftco-footer-widget mb-7">
                        <h2 className="ftco-heading-2">
                            <a href={`/${country.name.replace(/\s+/g, '-')}`} className="ski-chalet-link" target="_blank">
                                Book a Ski Chalet in {country.name}
                            </a>
                        </h2>
                        <ul className="list-unstyled">
                            {resorts.map((resort, j) => (
                                <li key={j}>
                                    <a href={generateUrl(country.name, resort.name, resort.id, country.id)} className="py-2 d-block" target="_blank">
                                        {resort.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </>
    );
};

export default FooterLinks;
