import React, { Component } from 'react'
import  Header  from '../ContentParts/Header';

export default class ErrorBoundary extends Component {
    state =
        {
            hasError: false,
            error: null,
            errorInfo: null
        }

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error,
            errorInfo
        });
    }

    render() {
        if (!this.state.hasError) {
            return this.props.children
        }
        return (
            <div>
                <Header bg_Id={"bg_4"} PageName={"Error"} PageBreadCrumbName={"Error"} location={this.props.location} />
                <section className="ftco-section-2">
                    <div className="container-fluid d-flex">
                        <div className="section-2-blocks-wrapper row no-gutters">
                            <div className="text-inner align-self-start">
                                <div className="errorPage">
                                    <h2 className="text-danger">Sorry the lifts are closed today due to high winds - please come back soon</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

