import React from 'react';

import '../../css/NavMenu.css';

const NumberController = (props) => {
    return (
        <div className={props.className}>
            <div className="numbersCounter">
                <span className="minus" onClick={props.DecreaseCount}>-</span>
                <input
                    type="number"
                    className="count-room counterInput"
                    name="counter"
                    id={props.id}
                    value={props.currentCount}
                    readOnly
                />
                <span className="plus" onClick={props.IncreaseCount}>+</span>
            </div>
        </div>
    );
}

export default NumberController;

