import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Checkbox, FormControlLabel, FormGroup, Typography, Box, Popover } from '@mui/material';
import { Container, Row, Col } from 'react-bootstrap';
import ChaletIcon from '@mui/icons-material/House'; // Import a chalet icon from MUI icons

const apiUrl = process.env.REACT_APP_API_URL;

const chunkArrayIntoThirds = (array) => {
    const chunkSize = Math.ceil(array.length / 3);
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
};


const DestinationSearchArea = ({ searchQuery, setsearchButtonDisabled, onSearchQueryChange }) => {
    const [navMenu, setNavMenu] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedResorts, setSelectedResorts] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [destinationTempVisible, setDestinationTempVisible] = useState(true);

    const [dialogBoxSelected, setdialogBoxSelected] = useState(false);

    const containerRef = useRef(null);

    useEffect(() => {
        const getItems = async () => {
            try {
                const res = await fetch(apiUrl + '/NavMenu');
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                const data = await res.json();
                setNavMenu(data);

                if (!dialogBoxSelected) {
                    // Prepopulate selected countries and resorts based on searchQuery
                    if (searchQuery.CountrySearchArray) {
                        setSelectedCountries(searchQuery.CountrySearchArray);
                    }
                    if (searchQuery.ResortIds) {
                        const preselectedResorts = searchQuery.ResortIds.map(resortId => ({
                            countryId: null, // You might need to update this based on your data structure
                            resortId
                        }));
                        setSelectedResorts(preselectedResorts);
                    }
                    setsearchButtonDisabled(searchQuery.CountrySearchArray.length === 0 && searchQuery.ResortIds.length === 0);
                }
            } catch (error) {
                console.error("Failed to fetch NavMenu:", error);
            }
        };

        getItems();
    }, [searchQuery]);

    const handleClick = (event) => {
        setdialogBoxSelected(true);
        setAnchorEl(containerRef.current);
        setDestinationTempVisible(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setDestinationTempVisible(true);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleCheckboxChange = (countryId, resortId) => {
        const currentIndex = selectedResorts.findIndex(
            (item) => item.countryId === countryId && item.resortId === resortId
        );

        const newSelectedResorts = [...selectedResorts];

        if (currentIndex === -1) {
            newSelectedResorts.push({ countryId, resortId });
        } else {
            newSelectedResorts.splice(currentIndex, 1);
        }

        setSelectedResorts(newSelectedResorts);

        const resortIds = newSelectedResorts.map(resort => resort.resortId);
        onSearchQueryChange({ ResortIds: resortIds, CountrySearchArray: [] });

        // Enable or disable the search button based on selection
        setsearchButtonDisabled(resortIds.length === 0 && selectedCountries.length === 0);
    };

    const handleCountryCheckboxChange = (countryId) => {
        const currentIndex = selectedCountries.indexOf(countryId);
        const newSelectedCountries = [...selectedCountries];

        if (currentIndex === -1) {
            newSelectedCountries.push(countryId);
            // Remove all individual resort selections for this country
            setSelectedResorts(selectedResorts.filter(item => item.countryId !== countryId));
            onSearchQueryChange({ ResortIds: [], CountrySearchArray: newSelectedCountries });
        } else {
            newSelectedCountries.splice(currentIndex, 1);
            onSearchQueryChange({ ResortIds: [], CountrySearchArray: newSelectedCountries });
        }

        setSelectedCountries(newSelectedCountries);

        // Enable or disable the search button based on selection
        setsearchButtonDisabled(newSelectedCountries.length === 0 && selectedResorts.length === 0);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // Determine the display text for the "Where" button
    let whereText = "Where";
    if (selectedCountries.length === 1 && selectedResorts.length === 0) {
        whereText = navMenu.find(country => country.country.id === selectedCountries[0]).country.name;
    } else if (selectedCountries.length === 0 && selectedResorts.length === 1) {
        const selectedResort = navMenu
            .flatMap(country => country.resorts)
            .find(resort => resort.id === selectedResorts[0].resortId);
        whereText = selectedResort.name;
    } else if (selectedCountries.length === 0 && selectedResorts.length > 1) {
        whereText = `${selectedResorts.length} resorts`;
    } else if (selectedCountries.length > 0 && selectedResorts.length > 0) {
        whereText = `${selectedCountries.length + selectedResorts.length} selections`;
    } else if (selectedCountries.length > 1 && selectedResorts.length === 0) {
        whereText = `${selectedCountries.length} countries selected`;
    }

    return (
        <div>
            <div
                id="container"
                className={destinationTempVisible ? "form-control noselect white-border destination-container" : "hideMe"}
                onClick={handleClick}
                ref={containerRef}
                style={{ cursor: 'pointer', padding: '8px', borderRadius: '4px', border: '1px solid #ced4da', display: 'flex', alignItems: 'center' }}
            >
                {(selectedCountries.length > 0 || selectedResorts.length > 0) && <ChaletIcon className="chalet-icon" style={{ marginRight: '8px' }} />}
                <span>{whereText}</span>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Container className="p-2 popover-container">
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="destination tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        {navMenu.map((countryData, index) => (
                            <Tab key={countryData.country.id} label={countryData.country.name} />
                        ))}
                    </Tabs>
                    {navMenu.map((countryData, index) => (
                        <TabPanel key={countryData.country.id} value={tabIndex} index={index}>
                            <Row>
                                <Col xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedCountries.includes(countryData.country.id)}
                                                onChange={() => handleCountryCheckboxChange(countryData.country.id)}
                                                name={`all-${countryData.country.name}`}
                                            />
                                        }
                                        label={`All of ${countryData.country.name}`}
                                    />
                                </Col>
                                {countryData.resorts.length > 0 && (
                                    chunkArrayIntoThirds(countryData.resorts).map((resortChunk, chunkIndex) => (
                                        <Col xs={12} sm={6} md={4} key={chunkIndex}>
                                            <FormGroup>
                                                {resortChunk.map((resort) => (
                                                    <FormControlLabel
                                                        key={resort.id}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedResorts.some(
                                                                    (item) => item.countryId === countryData.country.id && item.resortId === resort.id
                                                                )}
                                                                onChange={() => handleCheckboxChange(countryData.country.id, resort.id)}
                                                                name={resort.name}
                                                                disabled={selectedCountries.includes(countryData.country.id)} // Disable if countrywide selected
                                                            />
                                                        }
                                                        label={resort.name}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </Col>
                                    ))
                                )}
                            </Row>
                        </TabPanel>
                    ))}
                </Container>
            </Popover>
        </div>
    );
};

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default DestinationSearchArea;
